import ApiService, { HTTPMethod } from "./api.core";
import { Transformer } from "../models/Transformer";
import UserProfileModel from "../models/user-models/UserProfileModel";
import State from "../models/user-models/State";
import dayjs from "dayjs";
import Resizer from "../utilities/Resizer";
import { dataImageToFile, removeDuplicateAdditionalInsured } from '../services/utils'
import CreditCardModel from '../models/user-models/CreditCardModal'
import { AdditionalInsuredModel } from '../models/user-models/AdditionalInsuredModel'
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import DataLayerService from "../services/DataLayerService";
import { isNull } from "lodash";


export class UserApi {

  static async getProfile() {
    let resArr = await Promise.all([ApiService.fetch('users/me'), UserApi.getProfilePicture()]) 
    let res = resArr[0]
    let imageRes = resArr[1]
    if(res.ok) {
        let user = Transformer.initializeFromData(new UserProfileModel(), res.data)
        user.firstName = res.data.first_name
        user.lastName = res.data.last_name
        user.profilePicture = imageRes
        res.parsedData = user

        //TODO: a better solution to Set user id for Google Tag Manager Service
        DataLayerService.userId = user.userId
    }
    return res
  }

  static async getStates() {
      let response = await ApiService.fetch("general/administrative_divisions")
        if (response.ok) {
          let states = [];
          for (let value in response.data.administrative_divisions) {
            let state = new State();
            state.name = value;
            state.code = response.data.administrative_divisions[value];
            states.push(state);
          }
          states.sort(function(a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          response.parsedData = states;
        }
        return response
  }

  static async getAdditionalInsuredAdministrativeDivisions() {
      const response = await ApiService.fetch("general/additional_insured_administrative_divisions")
        if (response.ok) {
          let states = [];
          response.data.administrative_divisions.forEach(value=>{    
            states.push({name:value.name, code:value.code ,countryCode:value.country_code});
          })
          states.sort(function(a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          response.parsedData = states;
        }
        return response
  }

  static async saveProfilePicture(profileImage) {
    let newProfileImage = await Resizer.imageFileResizer(profileImage, 250, 250, "JPEG", 100, 0, 'base64')
    let resizedImage = dataImageToFile(newProfileImage, profileImage.name)
    return ApiService.uploadFile("users/me/profile_image/upload", resizedImage);
  }

  static async saveProfile(profile,offerId, companyLogo) {
    let formData = new FormData();
    formData.append("fullName", UserProfileModel.fullNameCreator(profile.user_info.fname, profile.user_info.lname))
    formData.append("firstName", profile.user_info.fname)
    formData.append("lastName", profile.user_info.lname)
    formData.append("company", profile.company.company_name)
    formData.append("address1", profile.address.address_line_1)
    formData.append("address2", profile.address.address_line_2)
    formData.append("city", profile.address.city)
    formData.append("administrativeDivision", profile.address.state)
    formData.append("zipcode", profile.address.zipcode)
    formData.append("commercial", profile.insurance_history.is_commercial == "yes")
    formData.append("eventsWithLargeNumberOfPeople", profile.insurance_history.events_with_large_number_of_people == "true")
    formData.append("commercialOperationsByRpacPilot", profile.insurance_history.commercial_operations_by_RPAC_pilot == "true")
    formData.append("overflightsOfPeople", profile.insurance_history.overflights_of_people == "true")
    formData.append("dateOfBirth", dayjs(new Date(2000, 2, 2, 6, 6, 6)).format("YYYY-MM-DD"))
    formData.append("droneLoss", profile.insurance_history.had_claim == "yes")
    formData.append("hadIncidentsOrLosses", profile.insurance_history.had_incidents_or_losses == "true")
    formData.append("hadFelony", profile.insurance_history.had_felony == "true")
    formData.append("operateBeyondVisualOfSight", profile.insurance_history.operate_beyond_visual_line_of_sight == "true")
    formData.append("intendedUse", profile.insurance_history.intended_use)
    formData.append("droneLossDescription", profile.insurance_history.had_claim == "yes" ? profile.insurance_history.drone_loss_description : "")
    formData.append("felonyDescription", profile.insurance_history.had_felony == "true" ? profile.insurance_history.felony_description : "")
    formData.append("incidentsOrLossesDescription", profile.insurance_history.had_incidents_or_losses == "true" ? profile.insurance_history.incidents_or_losses_description : "")
    formData.append("incidentsOrLossesDate", profile.insurance_history.had_incidents_or_losses == "true" ? profile.insurance_history.incidents_or_losses_date : dayjs(new Date(2000, 2, 2, 6, 6, 6)).format("YYYY-MM-DD"))
    formData.append("incidentsOrLossesValue", profile.insurance_history.had_incidents_or_losses == "true" ? profile.insurance_history.incidents_or_losses_value !== "" ? profile.insurance_history.incidents_or_losses_value :0 :0)
    formData.append("incidentsOrLossesMoreClaims", profile.insurance_history.had_incidents_or_losses == "true" ? profile.insurance_history.incidents_or_losses_more_claims == "true": "false" )
    formData.append("declarationsAgreementDescription", profile.insurance_history.declarations_agreement=="false" ? profile.insurance_history.declarations_agreement_description : "")


    formData.append("eventsWithLargeNumberOfPeopleDeclarationDescription", (profile.insurance_history.events_with_large_number_of_people =="true" && profile.selectedLiability !=1000000 && profile.selectedLiability !=500000)  ? profile.insurance_history.events_with_large_number_of_people_declaration_description : "")
    formData.append("eventsWithLargeNumberOfPeopleDeclaration", (profile.insurance_history.events_with_large_number_of_people =="true" && profile.selectedLiability !=1000000 && profile.selectedLiability !=500000) ? profile.insurance_history.events_with_large_number_of_people_declaration : "")

    formData.append("commercialOperationsByRPACPilotDescription", profile.insurance_history.commercial_operations_by_RPAC_pilot=="false" ? profile.insurance_history.commercial_operations_by_RPAC_pilot_description : "")
    formData.append("overflightsOfPeopleDescription", profile.insurance_history.overflights_of_people=="false" ? profile.insurance_history.overflights_of_people_description : "")

    formData.append("phoneNumber", profile.user_info.phone_number)
    formData.append("agentName", profile.agent_info.agent_name)
    formData.append("agentEmail", profile.agent_info.agent_email)
    formData.append("numberOfDroneUsersOperates", profile.number_of_drones_operate)
    formData.append("offerId", offerId)
    formData.append("selectedLiabilityLimit", profile.selectedLiability)
    formData.append("planDuration", profile.planDuration)
    formData.append("hasHull", profile.hasHull)
    formData.append("finalPrice", profile.finalPrice)
    if(profile.flowType === 'fleet') {
      formData.append("fleetSize", profile.fleetSize)
      formData.append("fleetYearlySize", profile.yearlySize)
      formData.append("fleetIntendedUse", profile.fleetIntendedUse)
      formData.append("fleetDocument", profile.fleet.file)
      formData.append("fleetIsPublishable", profile.fleetIsPublishable)
      formData.append("fleetIsProceduresControl", profile.fleetProceduresControl)
    }
    formData.append("flowType", profile.flowType)
    if (companyLogo) {
      let newProfileImage = await Resizer.imageFileResizer(companyLogo, 250, 250, "JPEG", 100, 0, 'base64')
      let resizedImage = dataImageToFile(newProfileImage, companyLogo.name)
      formData.append("companyLogo", resizedImage, resizedImage.name)
    }
    let res = await ApiService.fetch("users/me/save-with-image", HTTPMethod.POST, formData, false);
    if(res.ok) {
      const user = await UserApi.getProfile();
      return user;
    }
    else
      return res;
  }

  static async getProfilePicture() {
    let res = await ApiService.fetch('users/me/profile_image');
    if(res.ok)
      return res.data.image_url;
    else 
      return ""
  }

  static async updateCreditCard(token,carrier) {
    return await ApiService.fetch("creditcards/me?carrier="+carrier, HTTPMethod.POST,  token.id);
    }

  static async getCreditCardInfo(carrier) {
    let res = await ApiService.fetch("creditcards/me?" + 'carrier=' + carrier);
    console.log(res)
    if (res.ok) {
      let creditCardModel = new CreditCardModel();
      creditCardModel.lastFourDigits = res.data.last_four_digits;
      creditCardModel.expMonth = res.data.expiration_month.toString();
      creditCardModel.expYear = res.data.expiration_year.toString();
      creditCardModel.brand = res.data.brand ? res.data.brand.toLowerCase() : 'visa'
      res.parsedData = creditCardModel;
    }
    return res;
  }

  static async attachPaymentMethod(paymentMethodToken, isAttachedAlready, method, carrier){
    return await ApiService.fetch("creditcards/me/attach-payment-method",HTTPMethod.POST, { 
      payment_method_token: paymentMethodToken,
      is_attached_already: isAttachedAlready,
      carrier: carrier,
      method
    }, true);
  }

  static async setIsCommercial() {
    return await ApiService.fetch('users/me', HTTPMethod.PATCH, [{
      "value": true,
      "path": "/commercial",
      "op": "replace"
    }])
  }

  static async getAdditionalInsureds(insuranceId) {
    const res = await ApiService.fetch(`users/me/additionalInsureds/${insuranceId}`);
    if (res.ok && res.data){
      res.parsedData = removeDuplicateAdditionalInsured(res.data.sort((a, b) => b.id-a.id));
    }
    return res; 
  }

  static async saveAdditionalInsureds(body) {
    const res = await ApiService.fetch("users/me/additionalInsureds", HTTPMethod.POST,{additional_insured_list: body});
    return res; 
  }

  static async validateUser({coverageDate}) {
    coverageDate = dayjs(coverageDate).format();
    const res = await ApiService.fetch("users/me/validate-user-profile", HTTPMethod.POST, {coverage_date: coverageDate});
    return res
  }

  static async hasFailedActivationInLast5Days(){
    let res= await ApiService.fetch(`insurances/HasFailedActivationInLast5Days`, HTTPMethod.GET);
    return res
  }

  static async purchaseAfterCcFailure(){
    let res= await ApiService.fetch(`insurances/PurchaseAfterCcFailure`, HTTPMethod.POST);
    return res
  }
  
  static async allowDeclinations(body){
    let res = await ApiService.fetch("users/me/manage-declinations", HTTPMethod.POST,body);
    return res; 
  }
  static async declineDeclinations(body){
    let res = await ApiService.fetch("users/me/manage-declinations", HTTPMethod.POST,body);
    return res; 
  }

  static async updateHullEligibility(hullEligibility){
    let res = await ApiService.fetch("users/me/setHullEligibility", HTTPMethod.POST, hullEligibility);
    return res; 
  }

  static async updatePurchaseEligibility(purchaseEligibility){
    let res = await ApiService.fetch("users/me/setPurchaseEligibility", HTTPMethod.POST, purchaseEligibility);
    return res; 
  }

  static async updateLiabilityLimitDeclination(liabilityLimit){
    let res = await ApiService.fetch("users/me/setLiabilityLimitDeclination", HTTPMethod.POST, liabilityLimit);
    return res; 
  }
}